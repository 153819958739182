.main {
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    background-color: rgb(39, 60, 93, 0.061);
    min-height: 100vh;
}

.content {
    display: flex;
}

.left {
    width: 400px;
    padding-top: 20px;
    display: flex;
    margin-top: 50px;
}

.right {
    padding: 30px;
    padding-top: 30px;
    width: 100%;
    margin-top: 50px;

}

.title {
    margin-top: -10px;
    margin-left: -40px;
    font-family: 'Roboto', serif;
    margin-bottom: 20px;
    text-align: center;

}

.leftContent {
    padding-top: 50px;
    width: 250px;
    padding-right: 70px;
    border-right: 1.5px solid rgba(197, 196, 196, 0.873);
}

.stepIcon {
    margin-bottom: 58px;
    position: relative;
}

.dot {
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(197, 196, 196, 0.873);
    top: 55px;
    left: 11.2px;
}

.rightContent {
    padding-top: 55px;
    width: 100px;
    margin-left: -15px;
}

.round {
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', serif;
    font-size: 14px;
    font-weight: 400;
    transition: ease-in-out .3s;
}

.step {
    margin-bottom: 40px;
}

.stepTitle {
    font-size: 16px;
    font-family: 'Roboto', serif;
    text-align: right;
}

.stepDesc {
    font-family: 'Roboto', serif;
    font-size: 14px;
    color: gray;
    text-align: right;
    margin-top: -15px;
}

.tick {
    width: 16.5px;
    height: 16.5px;
}

.nextPrev {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  
}

.prevClick {
    font-family: 'Roboto', serif;
    font-weight: 400;
    margin-right: 20px;
    color: gray;
}

.prevClick::selection {
    background-color: transparent;
}

.nextClick {
    font-family: 'Roboto', serif;
    margin-left: 20px;
    font-weight: 400;
    color: #0000ff;
    cursor: pointer;
}

.nextClick::selection {
    background-color: transparent;
}

.verDiv {
    border-left: 1px solid rgba(128, 128, 128, 0.516);
    height: 25px;
}

.loader {
    border: 3px solid #0000ff;
    /* Light grey */
    border-top: 3px solid white;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.succContainer {
    width: 100%;
    position: absolute;
    left: 0px;
    height: 85vh;
    background-color: rgba(220, 220, 220, 0.745);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
}

.succMessHead {
    color: green;
    margin-bottom: 20px;
}

.addOne {
    width: 150px;
    height: 42px;
    background-color: #0000ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 22px;
    cursor: pointer;
}

/* ================================Media Queries===================================================== */

@media screen and (max-width: 1100px) {
    .main {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        background-color: rgb(39, 60, 93, 0.061);
        min-height: 100vh;
    }

    .content {
        display: flex;
    }

    .left {
        width: 260px;
        padding-top: 20px;
        display: flex;
        margin-top: 50px;
    }

    .right {
        padding-top: 30px;
        width: 100%;
        margin-top: 50px;

    }
}

@media screen and (max-width: 1000px) {
    .main {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        background-color: rgb(39, 60, 93, 0.061);
        min-height: 100vh;
    }

    .content {
        display: flex;
    }

    .left {
        width: 260px;
        padding-top: 20px;
        display: flex;
        margin-top: 50px;
    }

    .right {
        padding-top: 30px;
        width: 100%;
        margin-top: 50px;

    }
}


@media screen and (max-width: 950px) {
    .main {
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        background-color: rgb(39, 60, 93, 0.061);
        min-height: 100vh;
    }

    .content {
        display: flex;
    }

    .left {
        width: 270px;
        padding-top: 20px;
        display: flex;
        margin-top: 50px;
    }

    .right {
        padding-top: 30px;
        width: 100%;
        margin-top: 50px;

    }
}

@media screen and (max-width: 800px) {
    .main {
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        background-color: rgb(39, 60, 93, 0.061);
        min-height: 100vh;
    }

    .content {
        display: flex;
    }

    .left {
        width: 250px;
        padding-top: 20px;
        display: flex;
        margin-top: 50px;
    }

    .right {
        padding-top: 30px;
        width: 100%;
        margin-top: 50px;

    }
}

@media screen and (max-width: 650px) {
    .main {
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        background-color: rgb(39, 60, 93, 0.061);
        min-height: 100vh;
    }

    .content {
        display: flex;
    }

    .leftContent {
        padding-top: 50px;
        width: 250px;
        padding-right: 20px;
        border-right: 1.5px solid rgba(197, 196, 196, 0.873);
    }

    .left {
        width: 200px;
        padding-top: 20px;
        display: flex;
        margin-top: 50px;
    }

    .right {
        padding-top: 30px;
        width: 100%;
        margin-top: 50px;

    }
}

@media screen and (max-width: 550px) {
    .main {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        background-color: rgb(39, 60, 93, 0.061);
        margin-top: -50px;
        min-height: 100vh;
        padding-top: 30px;
    }

    .content {
        display: flex;
    }

    .leftContent {
        padding-top: 50px;
        width: 250px;
        padding-right: 20px;
        border-right: 1.5px solid rgba(197, 196, 196, 0.873);
    }

    .left {
        width: 200px;
        padding-top: 20px;
        display: none;
        margin-top: 50px;
    }

    .right {
        width: 100%;
        margin-top: 50px;
        padding: 15px;
        padding-top: 30px;
        border-radius: 0px;

    }

    .nextPrev {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
}