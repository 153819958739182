.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../images/login/bg1.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
  padding-right: 80px;
}

.loginContainer {
  /* width: 85vw; */
  height: 90%;
  background-color: white;
  box-shadow: var(--main-shadow);
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.logo {
  width: auto;
  height: 40px;
  position: relative;
  left: 50px;
  top: 40px;
}

.mainContainer {
  height: 100%;
  width: 100%;
  margin-top: 50px;
  display: flex;
}

.right {
  padding-right: 60px;
  width: 660px;
  height: 100%;
 
  
}
@media screen and (min-width: 1400px) {
  .right {
    margin-top: 40px;
  }
}

.loginDesc {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 20px;
  color: rgb(65, 64, 64);
}

.left {
  height: 85%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;

}

.leftImg {
 width: 400px;
}

.welcomeText {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.form {
  margin-top: 40px;
  /* box-shadow: 0 -0px 20px 0 rgb(28 9 80 / 5%); */
}

.loginBtn {
  width: 150px;
  height: 40px;
  background-color: #0000ff;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 22px;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid #0000ff;
  transition: ease-in-out 0.3s;
}

.loginBtn:hover {
  transition: ease-in-out 0.3s;
  width: 150px;
  height: 40px;
  background-color: white;
  color: #0000ff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 22px;
  margin-left: 5px;
  cursor: pointer;
}

.loginBtnLoad {
  transition: ease-in-out 0.3s;
  width: 150px;
  height: 40px;
  background-color: white;
  color: #0000ff;
  border: 1px solid #0000ff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 22px;
  margin-left: 5px;
  cursor: pointer;
}

/* input */
.inputField {
  position: relative;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 14px;
}

.label {
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #939191;
  font-size: 15px;
  transition: 0.2s all;
  cursor: text;
}

.input {
  width: 100%;
  font-family: "Roboto", sans-serif;
  border: 0;
  outline: 0;
  padding: 0.5rem 0;
  /* border: 2px solid black; */
  background-color: transparent;
  box-shadow: none;
  color: #111;
}

.emailContainer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 -0px 20px 0 rgb(28 9 80 / 5%);
  padding-bottom: 5px;
}

.passwordContainer {
  margin-top: 20px;
  box-shadow: 0 -0px 20px 0 rgb(28 9 80 / 5%);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-bottom: 5px;
  /* background-color: rgba(39, 60, 93, 0.061); */
}

.input:invalid {
  outline: 0;
}

.input:focus ~ .label,
.input:valid ~ .label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  top: -14px;
  color: #939191;
}

.iconContainer {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.endIconContainer {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon {
  width: 25px;
  margin-top: 8px;
  opacity: 0.4;
}

.CheckIcon {
  width: 18px;
  margin-top: 8px;
  opacity: 1;
}

.eyeIcon {
  width: 25px;
  margin-top: 8px;
  opacity: 0.4;
  cursor: pointer;
}

.errMessageCont {
  position: relative;
  width: 100%;
}

.errMessage {
  position: absolute;
  right: 5px;
  top: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: red;
  transition: ease-in-out 0.3s;
}

.loader {
  border: 3px solid #0000ff;
  /* Light grey */
  border-top: 3px solid white;
  /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 882px) {
  .left {
    display: none;
  }
}



@media screen and (max-width: 882px) {
  .main{
    padding-left: 30px;
    padding-right: 30px;
  }
  .loginContainer {
    height: 80%;
    background-color: white;
    box-shadow: var(--main-shadow);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }

  .right {
    padding-right: 20px;
    padding-left: 20px;
    width: 100vw;
    height: 100%;
  
  }

 

  .logo {
    width: auto;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 20px;
  }

  .welcomeText {
    margin-top: 40px;
    text-align: center;
  }

  .loginDesc {
    text-align: center;
    margin-top: 15px;
  }

  .loginBtn {
    width: 150px;
    height: 40px;
    background-color: #0000ff;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    border-radius: 22px;
    margin-left: 0%;
    cursor: pointer;
    border: 1px solid #0000ff;
    transition: ease-in-out 0.3s;
  }

  .btndiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginBtn:hover {
    width: 150px;
    height: 40px;
    background-color: #0000ff;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    border-radius: 22px;
    margin-left: 0px;
    cursor: pointer;
    border: 1px solid #0000ff;
    transition: ease-in-out 0.3s;
  }
}
