body {
  background-color: #f3f3f3;
}
.search-dashboard {
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}
.search-back-card {
  margin-top: 50px;
  width: 100%;
  height: 250px;
  background-color: rgb(60, 97, 169);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* z-index: -1; */
}
.search-back-card-text {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.left-cube-image {
  width: 100px;
  position: absolute;
  left: 10px;
  top: 10px;
  filter: brightness(0) invert(1);
}
.left-cube-image2 {
  width: 80px;
  position: absolute;
  left: 40px;
  bottom: 20px;
  filter: brightness(0) invert(1);
}
.left-cube-image3 {
  width: 70px;
  position: absolute;
  left: 120px;
  top: 70px;
  filter: brightness(0) invert(1);
}

.right-cube-image {
  width: 100px;
  position: absolute;
  right: 10px;
  top: 10px;
  filter: brightness(0) invert(1);
}
.right-cube-image2 {
  width: 80px;
  position: absolute;
  right: 40px;
  bottom: 20px;
  filter: brightness(0) invert(1);
}
.right-cube-image3 {
  width: 70px;
  position: absolute;
  right: 120px;
  top: 70px;
  filter: brightness(0) invert(1);
}
.search-main {
  padding-left: 60px;
  padding-right: 60px;
}
@media screen and (max-width: 900px) {
  .search-main {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.search-container {
  display: flex;
  justify-content: center;
  margin-top: -40px;
  max-width: 100%;
}

.search {
  border-radius: 10px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px;
  height: 82px;
  display: flex;
  align-items: center;
  width: 80%;
  z-index: 1;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  .search-container {
    margin-top: -30px;
  }
  .search {
    height: 60px;
  }
}

.input-container {
  width: 70%;
  display: flex;
}
.input-search {
  padding-left: 15px;
  padding-right: 40px;
  width: 100%;
  border: none;
  outline: none;
}
.search-buttons {
  width: 30%;
  display: flex;
  justify-content: space-between;
}
.vertical-line {
  width: 1px;
  height: 40px;
  background-color: #ccc;
}

.no-data-found-container {
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 50px;
}

.no-data-found {
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.no-data-image {
  width: 80%;
}

@media screen and (max-width: 1400px) {
  .input-container {
    width: 70%;
  }
  .search-buttons {
    width: 30%;
  }
}
@media screen and (max-width: 1150px) {
  .input-container {
    width: 60%;
  }
  .search-buttons {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  .input-container {
    width: 100%;
  }
  .search-buttons {
    position: absolute;
    width: auto;
    width: 80%;
    margin-top: 130px;
    justify-content: flex-end;
    margin-left: -10px;
  }
  .vertical-line {
    display: none;
  }
  .no-data-found-container {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 70px;
  }
}

.search-btn {
  background-color: #6ca0dc;
  color: white;
  border: #6ca0dc;
  padding: 4px;
  padding-left: 25px;
  padding-right: 25px;
}
.search-key-terms {
  font-weight: bolder;
  cursor: pointer;
  text-decoration: underline;
}
.search-key-terms:hover {
  color: #6ca0dc;
}

.recent-list {
  font-weight: 900;
}
.name,
.date_filed,
.courtName,
.caseNumber {
  color: hsl(212, 62%, 64%);
  text-decoration: underline;
}

.table {
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}
.table > tbody {
  vertical-align: middle;
  text-align: center;
}

.table > thead {
  text-align: center;
  background-color: #3c61a9;
  color: white;
  font-weight: bolder;
  font-size: 15px;
  border-bottom: 2px solid transparent;
}

.file-icon {
  width: 75px;
}

.download-file {
  cursor: pointer;
}

.edit-case {
  cursor: pointer;
}

.edit-icon {
  width: 20px; 
  height: 20px;
}

.date-search {
  background-color: #6ca0dc;
  color: white;
}

/* Center the loader */
#loader {
  position: absolute;
  left: 50%;
  top: 80%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.search-icon {
  width: 25px;
  height: 25px;
  margin-left: 8px;
}
.cross-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.3;
  margin-right: 30px;
  margin-top: 12px;
}
@media screen and (max-width: 900px) {
  .cross-icon {
    position: absolute;
    margin-top: -130px;
  }
}

.cross-icon-hide {
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0;
  margin-right: 30px;
}
.input-search:focus {
  outline: none;
  box-shadow: none;
}
.dropdown-toggle {
  background-color: #fff !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
@media screen and (max-width: 900px) {
  .dropdown-toggle {
    margin-right: 10px;
  }
}
.button-search {
  background-color: rgb(60, 97, 169);
  color: #fff;
  border: none;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;

  /* padding: 4px; */
}
@media screen and (max-width: 900px) {
  .button-search {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.button-search:focus {
  outline: none;
  box-shadow: none !important;
}
.pagination {
  align-self: flex-end;
}
.upperTableRow {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
}
@media screen and (max-width: 900px) {
  .upperTableRow {
    margin-top: 40px;
  }
}
.upperTableRow > h4 {
  color: #3c61a9;
}
.exportBtn {
  padding: 10px;
  display: flex;
  color: grey;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border-radius: 10px;
  border: 0.5px solid grey;
  cursor: pointer;
}
.borderBottom {
  height: 2px;

  margin: "auro";
  background-color: #3c61a9;
}
.tableTh {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: red;
}
.dropdown-btn > button {
  background-color: #3c61ac;
  border: 0px transparent;
}
.dropdown-btn > button:hover {
  background-color: #3c61ac;
}
.dropdown-btn > button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.dropdown-btn > button:active {
  background-color: #3c61ac;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  /* border: 0.9px solid; */
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #3c61ac;
  border-color: #3c61ac;
}
.pagination > li.active > a:focus {
  color: #fff;
  background-color: #3c61ac;
  border-color: #3c61ac;
}

/* Style the active class (and buttons on mouse-over) */
/* .pagination > li > a:hover {
  background-color: #3c61ac;
  color: white;
} */
.pagination > li:first-child > a,
/* .pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none !important;
} */
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px !important;
  border-top-right-radius: 4px;
  display: none !important;
}
